<template>
  <FormSection :title="title">
    <SelectPanel :loading="fetching" :empty="empty" :listKey="selectedType">
      <template #search>
        <SelectPanelInput v-model="apiSearch.searchQuery" />
      </template>
      <template #tabs>
        <Tabs v-model:active="selectedType" variant="text" size="md">
          <TabsItem v-for="tab in availableTypes" :key="tab.value" :value="tab.value">
            {{ tab.label }}
          </TabsItem>
        </Tabs>
      </template>
      <template v-if="!fetching" #items>
        <Box v-if="error" flex justify="center" alignItems="center" grow="grow">
          <ErrorState :error="error" @retry="retry()" />
        </Box>
        <CategoryWrapper
          v-else-if="apiSearch.searching"
          :items="apiSearch.data"
          data-test-id="audiences-search"
          :hasNextPage="apiSearch.hasNextPage"
          :requestNextPage="apiSearch.requestNextPage"
        >
          <SearchItem
            v-for="item in apiSearch.data"
            :key="item.id"
            :item="item"
            :searchQuery="apiSearch.searchQueryFormatted"
            :disabled="maxAudiencesSelected"
          />
        </CategoryWrapper>
        <CategoryWrapper
          v-else
          :items="apiCategory.data"
          data-test-id="audiences-browser"
          :hasNextPage="apiCategory.hasNextPage"
          :requestNextPage="apiCategory.requestNextPage"
        >
          <CategoryItem
            v-for="item in apiCategory.data"
            :key="`${item.type}-${item.id}`"
            v-slot="{ nextLevel, nextCategory }"
            :item="item"
            :level="0"
            :expanded="apiCategory.expanded"
            :disabled="maxAudiencesSelected"
          >
            <Category
              :channelId="channelId"
              :accountId="accountId"
              :category="nextCategory"
              :types="selectedTypes"
              :targets="targets"
              :level="nextLevel"
              :favorite="isFavoritesTab"
              :disabled="maxAudiencesSelected"
            />
          </CategoryItem>
        </CategoryWrapper>
      </template>
    </SelectPanel>
  </FormSection>
</template>

<script setup lang="ts">
import {
  Box, ErrorState,
  FormSection,
  SelectPanel,
  SelectPanelInput,
  Tabs, TabsItem, buildSelectOptionsFromRecord,
} from '@lasso/luikit'
import { computed, reactive, ref, toValue, watch } from 'vue'
import { arrayIncludes } from '@lasso/shared/utils'
import { useApiCombined } from '@lasso/shared/hooks'
import { AudienceTarget, AudienceType, ChannelType } from '@lasso/api-activation/activation'

import {
  SupportedAudienceType, audienceTypeLabels, audienceTypeToApiAudienceTypes, availableAudienceTypesByChannelId,
} from '../../../../shared'

import { useCardAudiences } from '../useCardAudiences'

import CategoryWrapper from './CategoryWrapper.vue'
import CategoryItem from './CategoryItem.vue'
import { useSearch } from './useSearch'
import Category from './Category.vue'
import { useCategory } from './useCategory'
import SearchItem from './SearchItem.vue'

const { withTitle } = defineProps<{
  withTitle: boolean
}>()

const {
  isDirectSold,
  channelId,
  accountId,
  maxAudiences,
  maxAudiencesSelected,
  isFeatureUniversalPixelsEnabled,
  isFeatureFavoriteAudienceEnabled,
} = useCardAudiences()!

const title = computed(() => {
  if (!withTitle) {
    return undefined
  }

  return maxAudiences.value === 1 ? 'Select audience' : 'Select audiences'
})

const extraTabs = ['', 'favorites'] as const

const selectedType = ref<SupportedAudienceType | typeof extraTabs[number]>('')

const isFavoritesTab = computed(() => selectedType.value === 'favorites')

const allTypes = computed(() => {
  return buildSelectOptionsFromRecord({
    '': 'All',
    'favorites': 'Favorite',
    ...audienceTypeLabels,
  }).filter((option) => {
    switch (option.value) {
      case 'favorites':
        return toValue(isFeatureFavoriteAudienceEnabled)

      default:
        return true
    }
  })
})

const allAvailableTypes = computed(() => {
  return (availableAudienceTypesByChannelId[channelId.value] /* v8 ignore next */ ?? []).filter((type) => {
    switch (type) {
      case AudienceType.UNIVERSAL_PIXEL_AUDIENCE_CONVERSION:
        return toValue(isFeatureUniversalPixelsEnabled)

      default:
        return true
    }
  })
})

const availableTypes = computed(() => {
  return allTypes.value.filter((type) => {
    return arrayIncludes(extraTabs, type.value) || arrayIncludes(allAvailableTypes.value, type.value)
  })
})

const selectedTypes = computed(() => {
  const types = (selectedType.value && !arrayIncludes(extraTabs, selectedType.value))
    ? [selectedType.value as SupportedAudienceType]
    : availableTypes.value.map(type => type.value).filter(type => !arrayIncludes(extraTabs, type))

  return types.flatMap(type => audienceTypeToApiAudienceTypes[type] /* v8 ignore next */ ?? [])
})

watch(channelId, () => {
  if (!arrayIncludes(allAvailableTypes.value, selectedType.value)) {
    selectedType.value = ''
  }
})

const targets = computed(() => {
  switch (channelId.value) {
    case ChannelType.ENDEMIC_DTC:
      return [AudienceTarget.PATIENT_CONSUMER, AudienceTarget.CAREGIVER]
    case ChannelType.ENDEMIC_HCP:
    case ChannelType.ENDEMIC_EHR:
      return [AudienceTarget.HEALTHCARE_PROVIDER]
    default:
      return isDirectSold ? [AudienceTarget.HEALTHCARE_PROVIDER] : []
  }
})

const apiSearch = reactive(useSearch({
  channelId,
  accountId,
  types: selectedTypes,
  targets,
  favorite: isFavoritesTab,
}))

const apiCategory = reactive(useCategory({
  channelId,
  accountId,
  category: () => null,
  types: selectedTypes,
  targets,
  favorite: isFavoritesTab,
  enabled: () => !apiSearch.searching,
}))

const { fetching, error, retry } = useApiCombined([apiSearch, apiCategory])

const empty = computed(() => {
  if (fetching.value || error.value) {
    return false
  }

  return apiSearch.searching ? apiSearch.data.length === 0 : apiCategory.data.length === 0
})
</script>
