import { createInjectionState, objEntries, objKeys } from '@lasso/shared/utils'
import { RefOrGetter } from '@lasso/shared/types'
import { Ref, computed, reactive, toValue, watch } from 'vue'
import { ChannelType, useActivationApi } from '@lasso/api-activation/activation'
import { UseFormAddonsContext, useApi } from '@lasso/shared/hooks'
import { SelectOptionType } from '@lasso/luikit'

import { getIsEndemicChannel } from '../../../shared'

import { AudienceAccountsRecord, CardAudienceFormData, SelectedAudience } from './types'
import { buildEngagement } from './utils'

export const [useProvideCardAudiences, useCardAudiences] = createInjectionState(({
  isCardEnabled,
  isDraft,
  isActive,
  adGroupId,
  marketerId,
  accountId,
  channelId,
  accounts,
  isSourceAdGroup,
  isDirectSold,
  isFeatureUniversalPixelsEnabled,
  isFeatureFavoriteAudienceEnabled,
  useFieldModels,
  useFieldModelsStartingWith,
  useFieldArray,
  useFieldArrayModels,
  resetField,
  getId,
  getInitialValue,
}: {
  isCardEnabled: RefOrGetter<boolean>
  isDraft: Readonly<Ref<boolean>>
  isActive: Readonly<Ref<boolean>>
  channelId: Readonly<Ref<ChannelType>>
  adGroupId: Readonly<Ref<number>>
  marketerId: Readonly<Ref<number>>
  accountId: Readonly<Ref<number>>
  accounts: Readonly<Ref<AudienceAccountsRecord>>
  isSourceAdGroup: Ref<boolean>
  isDirectSold: boolean
  isFeatureUniversalPixelsEnabled: RefOrGetter<boolean>
  isFeatureFavoriteAudienceEnabled: RefOrGetter<boolean>
} & UseFormAddonsContext<CardAudienceFormData>) => {
  const { isLassoTrigger } = useFieldModels(['isLassoTrigger'])

  const audience = reactive(useFieldModelsStartingWith('audience', [
    'audiences',
    'platformId',
    'accountId',
    'cpm',
    'newAudienceName',
    'automaticallyPushAudience',
  ]))

  const lassoTrigger = reactive(useFieldModelsStartingWith('lassoTrigger', [
    'sourceTypeId',
    'sourceGroups',
    'triggerEngagementId',
    'daysAfterEmailDelivered',
    'engagements',
    'logicCondition',
  ]))

  const { loading, onData, error, retry } = useApi(
    useActivationApi().getAdGroupAudiences,
    () => {
      return toValue(isCardEnabled)
        ? [{ adGroupId: adGroupId.value }]
        : null
    },
    { refetch: true },
  )

  onData(({ data }) => {
    resetField('audience.audiences', {
      value: (data ?? []).map((item): SelectedAudience => ({
        id: item.id ?? 0,
        name: item.name ?? '',
        audienceType: item.audienceType!,
        createdDate: item.createdDate ?? '',
        audienceTargetId: item.targetID,
        displayCpm: item.displayCPM ?? 0,
        socialCpm: item.socialCPM ?? 0,
        groupId: item.groupId ?? 0,
        excluded: item.excluded ?? false,
        matchedDeviceRecords: item.matchedDeviceRecords ?? 0,
      })),
    })
  })

  const isDataOnly = computed(() => channelId.value === ChannelType.DATA_ONLY)
  const isEndemicChannel = computed(() => getIsEndemicChannel(channelId.value))
  const shouldHideCpm = computed(() => isEndemicChannel.value)
  const maxAudiences = computed(() => isDataOnly.value ? 1 : Infinity)
  const canSelectAudiences = computed(() => (!isLassoTrigger.value || !isDataOnly.value) && !isSourceAdGroup.value)
  const maxAudiencesSelected = computed(() => audience.audiences.length >= maxAudiences.value)

  const engagements = reactive(useFieldArray('lassoTrigger.engagements'))
  const canAddEngagement = computed(() => engagements.fields.length < 2)
  const canDeleteEngagement = computed(() => engagements.fields.length > 1)

  const addEngagement = () => {
    engagements.push(buildEngagement())
  }

  const deduplicatedAccounts = computed(() => {
    const [deduplicatedAccounts] = objEntries(accounts.value)
      .reduce<[AudienceAccountsRecord, Set<string>]>((
        [deduplicatedAccounts, existingAccountIds],
        [platformId, accounts],
      ) => {
        accounts.forEach((account) => {
          if (existingAccountIds.has(account.id)) {
            return
          }

          deduplicatedAccounts[platformId] = deduplicatedAccounts[platformId] ?? []

          deduplicatedAccounts[platformId]!.push(account)
          existingAccountIds.add(account.id)
        })

        return [deduplicatedAccounts, existingAccountIds]
      }, [{}, new Set()])

    return deduplicatedAccounts
  })

  const platformAccounts = computed((): Array<SelectOptionType<string>> => {
    if (!audience.platformId) {
      return []
    }

    const platformAccounts = deduplicatedAccounts.value[audience.platformId] ?? []

    return platformAccounts.map(account => ({
      label: `${account.id} - ${account.type === 'advertiser' ? 'Adv' : 'Mar'}-Level`,
      value: account.id,
    }))
  })

  const platformIdDisabled = computed((): boolean => {
    return Boolean(getInitialValue('audience.platformId')) && Boolean(getInitialValue('audience.accountId'))
  })

  const availablePlatformIds = computed(() => objKeys(deduplicatedAccounts.value))
  const accountIdDisabled = computed(() => !audience.platformId || platformAccounts.value.length < 2)

  const getInitialAudience = (id: number) => {
    return getInitialValue('audience.audiences').find(audience => audience.id === id) ?? null
  }

  const buildAudienceCpm = (audience: Pick<SelectedAudience, 'displayCpm' | 'socialCpm'>): number => {
    let cpm = audience.displayCpm

    if (isDataOnly.value && audience.socialCpm > 0) {
      cpm = audience.socialCpm
    }

    return cpm
  }

  watch(() => audience.platformId, () => {
    if (platformAccounts.value.length === 1) {
      audience.accountId = platformAccounts.value[0]!.value
    }
    else if (audience.accountId) {
      audience.accountId = ''
    }
  })

  watch([isDataOnly, isLassoTrigger], ([isDataOnly]) => {
    if (isDataOnly && isDraft.value && audience.audiences.length > 0) {
      audience.audiences = []
    }
  })

  watch(channelId, () => {
    if (isDraft.value && audience.audiences.length > 0) {
      audience.audiences = []
    }
  })

  watch(() => lassoTrigger.sourceTypeId, () => {
    if (lassoTrigger.sourceGroups.length > 0) {
      lassoTrigger.sourceGroups = []
    }

    if (lassoTrigger.triggerEngagementId) {
      lassoTrigger.triggerEngagementId = null
    }
  })

  watch(() => lassoTrigger.triggerEngagementId, () => {
    if (lassoTrigger.daysAfterEmailDelivered) {
      lassoTrigger.daysAfterEmailDelivered = null
    }
  })

  // Prefill new audience data cpm with cpm from the selected audience for DOAGs
  // For lasso triggers this is done in the AudienceTriggers component
  watch([() => audience.audiences, isDataOnly], ([audiences, isDataOnly]) => {
    const firstAudience = audiences[0]

    if (isDraft.value && isDataOnly && firstAudience) {
      audience.cpm = buildAudienceCpm(firstAudience)
    }
  })

  return {
    loading,
    error,
    retry,

    marketerId,
    accountId,
    channelId,
    isActive,
    isDraft,
    isDataOnly,
    isSourceAdGroup,
    isEndemicChannel,
    isDirectSold,
    shouldHideCpm,
    maxAudiences,
    maxAudiencesSelected,
    canSelectAudiences,
    isFeatureUniversalPixelsEnabled,
    isFeatureFavoriteAudienceEnabled,

    useFieldArrayModels,
    getId,
    getInitialAudience,
    buildAudienceCpm,

    isLassoTrigger,
    audience,
    lassoTrigger,
    engagements,
    addEngagement,
    canAddEngagement,
    canDeleteEngagement,

    platformAccounts,
    platformIdDisabled,
    availablePlatformIds,
    accountIdDisabled,
  }
})
