import { useApiInfinite, useApiInfiniteSkip } from '@lasso/shared/hooks'
import { computed, toRef, toValue } from 'vue'
import { RefOrGetter } from '@lasso/shared/types'
import {
  AudienceTarget,
  AudienceType,
  ChannelType,
  GetAudiencesByCategoryQuery,
  useActivationApi,
} from '@lasso/api-activation/activation'

import { getIsCategory } from './utils'

export const useCategory = ({
  channelId,
  accountId,
  category,
  types,
  targets,
  favorite,
  enabled = () => true,
  pageSize = () => 25,
}: {
  channelId: RefOrGetter<ChannelType>
  accountId: RefOrGetter<number>
  category: RefOrGetter<number | null>
  types: RefOrGetter<AudienceType[]>
  targets: RefOrGetter<AudienceTarget[]>
  favorite: RefOrGetter<boolean>
  enabled?: RefOrGetter<boolean>
  pageSize?: RefOrGetter<number>
}) => {
  const api = useApiInfinite(
    useActivationApi().getAudiencesByCategory,
    (page): [GetAudiencesByCategoryQuery] => [{
      channel: toValue(channelId),
      advertiserId: toValue(accountId),
      types: toValue(types),
      targets: toValue(targets),
      category: toValue(category),
      onlyFavorites: toValue(favorite) ? true : undefined,
      paging: {
        skip: page.param,
        pageSize: page.size,
      },
    }],
    {
      ...useApiInfiniteSkip(({ responseData }) => responseData.total ?? 0),
      pageSize: toRef(pageSize),
      getDataArray: data => data.data ?? [],
      cache: true,
      refetch: true,
      enabled: toRef(enabled),
    },
  )

  const expanded = computed((): boolean | undefined => {
    return (api.data.value.length === 1 && getIsCategory(api.data.value[0]!)) || undefined
  })

  return {
    ...api,
    expanded,
  }
}
